$color_1: #707070;
$color_2: #001935;
$color_3: black;
$color_4: white;
$color_5: #ffffff;
$color_6: #fff;
$color_7: #ffff;
$color_8: #2443ac;
$color_9: rgb(36, 67, 172);
$color_10: #212529;
$font-family_1: "IBM Plex Sans",
sans-serif;
$font-family_2: "Catamaran",
sans-serif;
$font-family_3: 'boxicons';
$background-color_1: transparent;
$background-color_2: var(--blue);
$background-color_3: rgba(var(--blue-rgb), 0.15);
$border-color_1: #dc3545;
$border-color_2: #198754;
$border-color_3: rgba(0, 1, 0, 1);
$border-color_4: var(--blue);
$border-color_5: rgba(var(--blue-rgb), 0.15);

// @import "https://fonts.googleapis.com/css?family=Catamaran:400,600|IBM+Plex+Sans:400,500,500i,600,700&display=swap";

/* li.nav-item a {
    color: black !important;
  } */
/* Validate form css start */
/* Validate form css end */
#root~iframe[style*="2147483647"] {
    z-index: -1000 !important;
    display: none;
}

.no-validate-icon-select {
    --bs-form-select-bg-icon: none !important;
}

.no-validate-icon {
    background-image: none !important;
}

::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
    background: rgba(#061322, 0.1) !important;
}

::-webkit-scrollbar-track {
    border-radius: 10px !important;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    background-color: rgba(#061322, 0.75) !important;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #061322 !important;
}

.css-1fdsijx-ValueContainer {
    padding: 0 !important;
}
.other-option{
    .signup-btn{
        min-width: 80px !important;
    }
}
.top-2{
    top: 2px;
}
.breadcrumb .fa-file-invoice {
    margin-top: 3px !important;
        margin-left: 6px !important;
        margin-right: 6px !important;
    }
.l {
    border: 1px solid red;
}

select#status{
    text-align: left;
    appearance: none;
    background-image: linear-gradient(45deg, transparent 49%, gray 49%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 17px) calc(1em + 1px), calc(100% - 12px) calc(1em + 1px), calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 0px;
    background-repeat: no-repeat;

}

#my-profile-image .manage_avtart, #my-profile-image-staff-profile .manage_avtart {
    width: 200px;
    position: absolute;
    left: -125px;
    top: 45px;
}
#my-profile-image .manage_avtart, #my-profile-image-staff-profile .manage_avtart {
    left: -100px;
}
.table_manage_border table tbody tr:first-child td {
    border: none !important;
    padding-top: 0 !important;
}
.btn_below_mobile{
    display: none !important;
}
.accordian_email_date{
    position: absolute;
    right: 50px;
}
// ===========written by Tausif 26 Oct 2:50PM ===========
// .invalid-feedback.invalid-custom-feedback.d-block.manage_avtart {

//     position: absolute;
//     left: -80px;
//     width: 190px;
//     top: 47px;
//     line-height: 17px;
//     font-size: 14px;
// }
// ===========written by Tausif 26 Oct 2:50PM ===========
.break_word{
    white-space: normal !important;
    word-break: break-all !important;
}
// .table tbody tr td{
//     vertical-align: middle;
//     white-space: normal;
//     word-break: break-all;
// }
// thead tr th {
//     vertical-align: middle;
//     white-space: nowrap !important;
// }

// .wrap{
//     word-break: break-all !important;
//     white-space: normal !important;
    
// }

// .new style
.viewApplication .dropdown-menu {
    left: auto !important;
    right: -16px !important;
    top: calc(100%  + 8px) !important;
}

 .nav-tabs.viewApplication .nav-item {
    position: relative;
}
.nav-tabs.viewApplication .nav-item:has(.dropdown.show) {
    z-index: 99999;
}
.top_widget .body{
   display: flex;
   justify-content: space-between;
   align-items: center;
   color: var(--font-color);
    font-weight: 400;
    column-gap: 10px;
}
.top_widget #top_widget_body{
    padding: 20px 10px !important;
}
.top_widget .body .icon {
    width: 50px;
    height: 50px;
    min-width: 50px !important;
    min-height: 50px !important;
    background: var(--font-xlight);
    border-radius: 50px;
    position:static !important;
}
.top_widget .body .content{
    display: flex;
    column-gap: 10px;
}

.job-card-two .manage_job_card{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

// ======================
.table-first {
    width: 300px;
}

.table-third {
    width: 300px;
}

.table-third .dataTables_filter label {
    margin-bottom: 0 !important;
}

.table-third .dataTables_filter label input {
    height: 38px;
    border: 1px solid #1111118a;
    border-radius: 5px;
    background-position-y: center !important;
}

.dt-custom-filter.table-second {
    margin-left: auto;
}
@media (max-width:991.98px){
    .table-first {
        width: 180px;
    }
    
    .table-third {
        width: 200px;
        margin-left: 14px;
    }
}

@media (max-width:767.98px){
    .table-first {
        width: 100%;
    }
    
    .dt-custom-filter.table-second {
        width: 100%;
    }
    
    .dt-custom-filter.table-second select {
        width: 100%;
    }
    
    .table-third {
        width: 100%;
        margin: 0;
    }
}
.with_hover_heading{
    cursor: pointer !important;
}
.withHover{
    white-space: normal !important;
    word-break: break-all !important;
}
h3:hover .withHover{
display: none;
}
h3 .withoutHover{
display: none;
}
h3:hover .withoutHover{
    display: block;
    white-space: normal !important;
    word-break: break-all !important;
}



a.company:hover,
a.company:focus {
  color: #007bff !important;
}

body {
    font-size: 17px;
    font-family: $font-family_1;
    color: $color_1;
    line-height: 1.6;
}

.btn:hover {
    .bi-x-circle-fill {
        color: black !important;
        z-index: 9999 !important;
    }
}

.bi-x-circle-fill {
    color: #dc3545 !important;
    z-index: 9999 !important;
}

nav.navbar.navbar-expand-lg.bg-primary.py-3 {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 99;

}
.gallery-img {
    .download_btn {
        position: absolute;
        top: -10px;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        justify-content: end;
        align-items: start;
        color: #35af57f7;
        font-size: 24px;

    }
}

.gallery-img {
    img {
        width: 250px;
        height: auto;
        object-fit: cover;
    }
}

.gallery-img:hover {
    .download_btn {
        display: flex;
    }
}

.avtar_profile_manage {
    .manage_avtart {
        position: absolute;
        top: 45px;
        left: -85px;
        width: 200px;
    }
}

// =============Eye============
.eye_btn {
    border: none;
    outline: none;
    background-color: #001935;
    border: 1px solid #001935;
    width: 90px;
    padding: 5px 0px;
    border-radius: 5px;
    color: white;
    font-weight: 600;

    &:hover {
        background-color: white;
        border: 1px solid #001935;
        color: #001935;

    }
}
.massage-max-width{
    max-width: 80% !important;
}

// .customSelect{
//     z-index: 980 !important;
// }
.DraftEditor-editorContainer {
    z-index: 0 !important;
}
.payment_box {
    width: 250px;
    border: 2px solid #2443ac;
    max-width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    img {
        width: 40px !important;
        height: 40px !important;
        margin: 10px auto;
    }
    h2 {
        font-size: 28px !important;
        font-weight: 700;
        margin-top: 25px;
        font-family: monospace;
        color: #2443ac;
        line-height: 28px;
    }
}
// =========
.paypal-buy-now-button {
	display: inline-flex;
  position: relative;
  background: #FF9933;
  border-radius: 5px;
  border: 1px solid #FF9933;
  box-shadow: inset 0 1px 0 0 #FFD699;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 0 16px;
  height: 48px;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  width: 180px;
  img{
    border: 1px !important;
    z-index: 9 !important;
        max-width: 70px !important;
        margin-left: 10px;
        width: 35px;
  }

  &:before {
    content: "";
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    border-radius: 5px;
    background: linear-gradient(to bottom, #fff0a8 0%, #f9b421 100%);
    z-index: 1;
  }

  &:after {
    content: "";
    border-radius: 4px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #FF9933;
    box-shadow: inset 0 1px 0 0 #FF9933;
    z-index: 1;
  }

  span {
    padding-top: 3px;
    padding-right: 7px;
    // text-shadow: 0 1px 0 #FF9933;
    z-index: 2;
  }

  svg {
    filter: drop-shadow(0 1px 0 #FFFFFF);
    z-index: 2;
  }
}
// ==============================================Stripe=============================================
.stripe-connect {
    background: #635bff;
    display: inline-block;
    height: 48px;
    text-decoration: none;
    min-width: 180px;
  
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
  
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  
    -webkit-font-smoothing: antialiased;
    padding: 0;
  }
  
  .stripe-connect span {
    color: #ffffff;
    display: block;
    font-family: sohne-var, "Helvetica Neue", Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    padding: 17px;
    position: relative;
    text-align: left;
    padding-right: 80px;
    // text-shadow: 0 1px 0 #FFF;
  }
  
 
  
  .stripe-connect.slate {
    background: #0a2540;
  }
  
  .stripe-connect.slate:hover {
    background: #425466;
  }
  

  
  .stripe-connect span::after {
    background-repeat: no-repeat;
    background-size: 67.58px;
    content: "";
    position: absolute;
    width: 80px;
    height: 100%;
    top: 0;
    right: 0;
    background-position: center;
}
  
  /* Logos */
  .stripe-connect span::after {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.4, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
  }
  
  

// ==============
// ==============
.candidate_modal_section {
    position: absolute;
    top: 9%;
    left: 50%;
    transform: translate(-50%,-50%);
    /* z-index: 1; */
    background: white;
}
// ==============
.key-highlights-body {
  
    .common-card {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding-top: 22px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        .common-card-icon {
            width: 48px;
            height: 48px;
            text-align: center;

            img {
                width: 46px;
                height: 46px;
                border-radius: 100px;
                border: 1px solid #e7e7f1;
            }
        }

        .common-card-desc {
            padding-left: 10px;
        }
    }

    .name {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: $color_2;
    }

    .label {
        margin-top: 6px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $color_1;
    }
}
.posted-by.manage_posted{
    img {
        width: 150px;
        height: 150px;
        border-radius: 100px;
        border: 1px solid #e7e7f1;
    }
    h4{
        padding-top: 20px;
        padding-left: 10px;
       
    }
}
// ============================
// ===============
.manage_accordion_button .accordion-button {
    height: 100px !important;
}
.ar-fa-solid{
    margin-left: 4px !important;
}
.main-pdf-box {
    display: flex;
    column-gap: 5rem;
    flex-wrap: wrap;

    .pdf-box {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .pdf-icon {
            position: relative;

            img {
                width: 120px;
            }

            &:hover {
                .show-button {
                    opacity: 1;
                }

                // .show-box{
                //     opacity: 1;
                // }

            }
        }

        //    .show-box{
        //     opacity: 0;
        //     transition: opacity .35s ease;
        //     position: absolute;
        //     transform: translate(66px, -41px);
        //     background-color:rgb(236, 248, 2);
        //     width: 250px;
        //     height: 200px;
        //     z-index:9 !important;
        //     display: inline-block;
        //    }
        .show-button {
            opacity: 0;
            transition: opacity .35s ease;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: rgba(234, 231, 231, 0.35);
            width: 216px;
            height: 200px;

            // border-radius: 11% 37% 15% 13%;
            .preview-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                position: absolute !important;
                top: 50% !important;
                right: 25px !important;
                transform: translateY(-50%);
                display: inline;

                i {
                    display: block;
                    margin-bottom: 10px;
                    font-size: 18px;
                    color: #001935;
                    cursor: pointer;

                    &:nth-child(1) {
                        margin-left: 4px;
                    }

                    &:nth-child(3) {
                        margin-left: 2px;
                    }
                }
            }
        }
    }
}
td.logo_td {
    min-width: 100px;
}
.main-pdf-box1 {
    display: flex;
    column-gap: 2rem;
    flex-wrap: wrap;

    .pdf-box {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 190px;

        .pdf-icon {
            position: relative;

            img {
                width: 120px;
            }

            &:hover {
                .show-button {
                    opacity: 1;
                }
            }
        }

    }
}

// ===============
.form-flag-height38 .iti__selected-flag {
    height: 38px;
}

.iti__selected-flag {
    max-height: 58px;
}

.iti__flag-container {
    height: 40px;
}

img {
    max-width: 100%;
}

.bg-primary {
    background-color: #010c29 !important;
}

.pt-100 {
    padding-top: 100px;
}

.pb-70 {
    padding-bottom: 70px;
}

.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pt-50 {
    padding-top: 50px;
}


a {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }

    &:focus {
        text-decoration: none;
    }
}

p {
    font-size: 17px;
    color: $color_1;
    line-height: 1.8;
}

button {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    background-color: $background-color_1;

    &:focus {
        outline: 0;
    }
}

// .l{
//     border: 1px solid red;
// }

h1 {
    color: $color_2;
    font-weight: bold;
}

h2 {
    color: $color_2;
    font-weight: bold;
}

h3 {
    color: $color_2;
    font-weight: bold;
    font-size: 22px;
}

h4 {
    color: $color_2;
    font-weight: bold;
}

h5 {
    color: $color_2;
    font-weight: bold;
}

h6 {
    color: $color_2;
    font-weight: bold;
}

.form-control {
    &:focus {
        -webkit-box-shadow: 0 0 0 0;
        box-shadow: 0 0 0 0;
    }
}

.d-table {
    width: 100%;
    height: 100%;
}

.d-table-cell {
    display: table-cell;
    vertical-align: middle;
}

.pt-100 {
    padding-top: 100px;
}

.pb-70 {
    padding-bottom: 70px;
}

.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}

.section-title {
    margin-bottom: 60px;

    h2 {
        font-size: 38px;
        font-weight: 600;
        max-width: 550px;
        margin: 0 auto 20px;
        line-height: 1;
        text-transform: capitalize;
    }

    p {
        max-width: 750px;
        margin: 0 auto;
        color: $color_3;
    }
}

.job-style-two {
    p {
        color: $color_4;
    }
}

.condidate-item {
    .candidate-text {
        .bottom-text {
            a {
                i {
                    &:hover {
                        background: linear-gradient(#081c3c 0%, #21447c 100%);
                        color: $color_5;
                        -webkit-transition: 0.5s;
                        transition: 0.5s;
                        background: #fd1616;
                        color: $color_5;
                        -webkit-transition: 0.5s;
                        transition: 0.5s;
                    }

                    color: $color_6;
                    border: 1px dashed #fff;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    border-radius: 50px;
                }

                float: right;
            }

            border-top: 1px dashed #e2caca;
            margin-top: 14px;
            padding: 14px 0 0;
            line-height: 1;

            p {
                margin-bottom: 0;
                font-size: 16px;
                float: left;
                color: $color_6;

                i {
                    font-size: 18px;
                    top: 3px;
                    position: relative;
                }
            }
        }

        background: #001935;
        position: absolute;
        bottom: -98px;
        left: 0;
        right: 0;
        max-width: 95%;
        margin: auto;
        -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        padding: 20px;
        border-radius: 5px;
        opacity: .8;

        h3 {
            margin-bottom: 15px;

            a {
                color: $color_6;
            }
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                list-style: none;
                font-size: 15px;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    text-align: center;
    max-width: 300px;
    position: relative;
    margin-bottom: 100px;
    border: 4px solid #001935;
    border-radius: 20px;
    box-shadow: 5px 10px #001935;

    .candidate-img {
        border-radius: 10px;

        img {
            border-radius: 10px;
        }
    }

    .candidate-social {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-transition: 0.5s;
        transition: 0.5s;

        a {
            i {
                background: #fff;
                color: $color_9;
                width: 35px;
                height: 35px;
                border-radius: 30px;
                line-height: 35px;
                margin: 0 3px;
                -webkit-transition: 0.5s;
                transition: 0.5s;

                &:hover {
                    background: #001935;
                }
            }
        }
    }

    &:hover {
        .candidate-social {
            bottom: 120px;
        }

        .candidate-text {
            h3 {
                a {
                    color: $color_4;
                }
            }
        }
    }
}

.theme-btn {
    .default-btn {
        font-size: 18px;
        font-weight: 500;
        font-family: $font-family_2;
        background: linear-gradient(#081c3c 0%, #21447c 100%);
        color: $color_5;
        margin-right: 15px;
        border: 1px solid transparent;
        padding: 10px 25px;
        display: inline-block;
        border-radius: 5px;

        &:hover {
            background: #001935;
            border: 1px solid #ffffff;
        }
    }
}

.carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    position: relative;
    left: -40px;
    top: -12px;
}

.carousel-control-next-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    position: relative;
    right: -40px;
    top: -12px;
}

.carousel-control-next {
    &:hover {
        color: $color_6;
        text-decoration: none;
        outline: 0;
        opacity: 1;
        transform: scale(1.04);
        transition: 0.2s;
    }

    color: $color_6;
    text-decoration: none;
    outline: 0;
    opacity: 1 !important;
    transition: 0.2s;
}

.carousel-control-prev {
    &:focus {
        color: $color_6;
        text-decoration: none;
        outline: 0;
        opacity: 1;
        transform: scale(1.04);
        transition: 0.2s;
    }

    &:hover {
        color: $color_6;
        text-decoration: none;
        outline: 0;
        opacity: 1;
        transform: scale(1.04);
        transition: 0.2s;
    }

    color: $color_6;
    color: $color_6;
    text-decoration: none;
    text-decoration: none;
    outline: 0;
    outline: 0;
    opacity: 1 !important;
    opacity: 1 !important;
    transition: 0.2s;
    transition: 0.2s;
}

ol {
    padding-left: 0;
}

ul {
    padding-left: 0;
}

a.nav-link.active {
    color: $color_6 !important;
}

a.nav-link {
    color: $color_6 !important;
}

.navbar {
    .navbar-toggler:focus {
        text-decoration: none;
        outline: 0;
        box-shadow: none !important;
    }

    .navbar-toggler-icon {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        background-image: var(--bs-navbar-toggler-icon-bg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        filter: invert(1) !important;
    }

    .signin-btn {
        color: #ffffff;
        font-family: "Catamaran", sans-serif;
        font-size: 16px;
        font-weight: 600;
        padding: 5px 20px;
        background: #2443ac;
        border-radius: 5px;
        white-space: nowrap;
    }
}

//=====================Form Profile Not Supported=======================


.was-validated :invalid~.invalid-feedback{
    display: block!important
}

.navbar-area {
    .main-nav {
        background: #010c29;
        padding: 20px 0;
        position: relative;

        .navbar {
            padding: 0;

            .navbar-brand {
                padding: 0;
            }

            .navbar-nav {
                .nav-item {
                    padding: 10px 0;

                    .nav-link {
                        text-transform: capitalize;
                        font-family: $font-family_2;
                        font-size: 17px;
                        color: $color_7;
                    }

                    .nav-link.dropdown-toggle {
                        &::after {
                            border: 0;
                            content: '\ea4a';
                            font-family: $font-family_3;
                            vertical-align: middle;
                            line-height: 1;
                        }
                    }

                    .nav-link.active {
                        color: $color_8;
                    }

                    .dropdown-menu {
                        padding: 0;
                        border-radius: 0;
                        background: none;

                        .nav-item {
                            padding: 0;

                            .nav-link {
                                border-bottom: 1px dashed #4d5877;
                                background: #2443ac;
                                font-size: 15px !important;

                                &:hover {
                                    color: $color_5;
                                    background: #657294 !important;
                                }
                            }

                            .nav-link.active {
                                color: $color_5;
                                background: #657294 !important;
                            }

                            &:last-child {
                                .nav-link {
                                    border-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }

            .other-option {
                .signin-btn {
                    color: $color_5;
                    font-family: $font-family_2;
                    font-size: 16px;
                    font-weight: 600;
                    padding: 5px 20px;
                    background: #2443ac;
                    border-radius: 5px;

                    &:hover {
                        background: #ffffff;
                        color: $color_2;
                    }
                }

                .signup-btn {
                    color: $color_5;
                    font-family: $font-family_2;
                    font-size: 16px;
                    font-weight: 600;
                    padding: 5px 20px;
                    background: #2443ac;
                    border-radius: 5px;
                    background: none;
                    padding: 0;
                    margin-right: 10px;

                    &:hover {
                        background: #ffffff;
                        color: $color_2;
                        color: $color_8;
                        background: none;
                    }
                }
            }
        }
    }
}

.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 999;
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    width: 100% !important;
}

i.bx.bx-filter-alt {
    color: $color_7;
}

i.bx.bx-location-plus {
    color: $color_6;
}

.condidate-slider {
    .owl-dots {
        line-height: 1;
        margin-top: 25px !important;

        .owl-dot {
            span {
                background: #fdc8bb !important;
                margin: 0 5px;
            }
        }

        .owl-dot.active {
            span {
                background: #fd1616 !important;
            }
        }
    }
}

.input-group {
    .invalid-feedback {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
    }
}

.was-validated {
    .react-select.is-invalid {
        div {
            border-color: $border-color_1 !important;
            border-radius: 5px !important;

            &:hover {
                border-color: $border-color_1 !important;
            }
        }
    }

    .react-select.is-valid {
        div {
            border-color: $border-color_2 !important;
            border-radius: 5px !important;

            &:hover {
                border-color: $border-color_2 !important;
            }
        }
    }

    .react-select {
        div {
            &:hover {
                border-color: $border-color_3 !important;
            }
        }
    }

    .form-control {
        &:invalid {
            border-color: $border-color_1 !important;
        }
    }
}

.needs-validation {
    div {
        &:has(.invalid-custom-feedback) {
            .invalid-feedback {
                display: none;
            }
        }
    }
}
@-moz-document url-prefix() {
    .needs-validation div .invalid-custom-feedback~.invalid-feedback { display: none; }
    }
.form-control.is-invalid {
    border-color: $border-color_1 !important;
}

.pagination-sm {
    .page-item.previous.disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }

    .page-item.next.disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }

    .page-item.previous {
        .page-link {
            width: 74px;
            font-weight: 500;
        }
    }

    .page-item.next {
        .page-link {
            width: 74px;
            font-weight: 500;
        }
    }

    .page-item.active {
        .page-link {
            background-color: $background-color_2;
            border-color: $border-color_4;
            color: $color_6;

            &:hover {
                background-color: $background-color_2;
                border-color: $border-color_4;
                color: $color_6;
            }
        }
    }

    .page-item {
        &:hover {
            .page-link {
                background-color: $background-color_3;
                border-color: $border-color_5;
            }
        }

        .page-link {
            height: 30px;
            min-width: 30px;
            text-decoration: none;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            border: 1px solid #dee2e6;
            margin-left: 2px;
            cursor: pointer;
            text-align: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            border-radius: 0.25rem;
            font-size: 14px;
            color: $color_10;
        }
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.footer-area {
    &::before {
        right: 155px;
        top: 180px;
        position: absolute;
        content: '';
        background-image: url('./img/footer.png');
        background-position: center center;
        background-size: cover;
        width: 140px;
        height: 138px;
        right: 60px;
        top: 70px;
        opacity: .2;
        -webkit-animation: rotate 30s infinite linear;
        animation: rotate 30s infinite linear;
    }

    .footer-widget.pl-60 {
        padding-left: 35px;
        padding-left: 0;
        padding-left: 0;
    }

    .footer-widget {
        p {
            font-size: 15px;
            font-size: 15px;
            color: $color_6;
        }

        h3 {
            &::before {
                position: absolute;
                content: '';
                background: #ffffff;
                height: 2px;
                width: 50px;
                left: 0;
                bottom: -15px;
            }

            color: $color_5;
            position: relative;
            margin-bottom: 40px;
            font-weight: 600;
        }

        a {
            color: $color_6 !important;
        }


        span {
            color: $color_7;
        }

        li {
            list-style: none !important;
            color: $color_6;
        }

        .footer-social {
            a {

                i {
                    color: #060f24;
                    background: #fff;
                    font-size: 18px;
                    width: 35px;
                    height: 35px;
                    text-align: center;
                    border-radius: 50px;
                    line-height: 35px;
                    -webkit-transition: .5s;
                    transition: .5s;

                    &:hover {
                        background: #2443ac;
                        color: #fff;
                    }
                }
            }
        }

        ul {
            li {
                &:hover {
                    color: #fff !important;
                }

                a {
                    &:hover {
                        color: #fff !important;
                    }

                    i {
                        color: $color_7;
                        top: 2px;
                        position: relative;
                    }
                }
            }
        }
    }

    background: #060f24;
    position: relative;

    .bx {
        color: $color_7;
        margin-right: 7px;
    }

    i.bx.bx-chevrons-right.bx-tada:hover {
        color: #fff;
    }

    .footer-area .footer-widget a:hover {
        color: #fff !important;
    }

    .footer-area .footer-widget .footer-social a i:hover {
        background: #060f24 !important;
        color: #ffff !important;
        border: 1px solid #fff !important;
    }
}

// @media screen and (max-width: 991.98px) {
//     .navbar {
//         max-height: 100vh;
//         overflow-y: auto;
//     }
// }

@media screen and (max-width: 768px) {
    .navbar-nav {
        li {
            display: table-cell;
            position: inherit;
            text-align: left !important;
        }
    }

    footer {
        position: relative;
        text-align: left !important;
    }
}


.priview_pdf {
    width: 100%;
    max-width: 100%;
    height: 800px;
}

.manage-pdf {
    width: 100%;
    max-width: 90%;
    height: 220px;
    aspect-ratio: 16/9;
}

.main-profile {

    // padding: 31px 17px;
    // box-shadow: 5px 5px 10px #888888;
    .profile-image {

        .profile_pic {
            width: 200px;
            max-height: 200px;
            display: inline-block;
        }

        .file-upload {
            display: none;
        }

        .circle {
            border-radius: 100% !important;
            overflow: hidden;
            width: 128px;
            height: 128px;
            border: 2px solid rgba(255, 255, 255, 0.2);
            position: absolute;
            top: 0px;
            position: relative;
            display: flex;
            left: 0px;
            justify-content: center;
            box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
        }

        img {
            max-width: 100%;
            height: auto;

        }

        .p-image {

            position: absolute;
            bottom: 12px;
            right: -4px;
            color: #666666;
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.75);
            box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.5);
            cursor: pointer;
        }

        .p-image:hover {
            transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
        }

        .upload-button {
            font-size: 1.2em;
        }

        .upload-button:hover {
            transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
            color: #999;
        }
    }
}

section.about-section.py-5 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    .account {
        box-shadow: 5px 5px 10px #888888;
        padding: 88px;
        background-color: #010c29;

        h2 {
            color: #ffff !important;
        }

        p {
            color: #Ffff;
        }
    }

    .close-button-sign-up {
        margin-left: auto;
    }

}

.input-group.password-control.mb-2 {
    position: relative;
}

.password-eye {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 99;
    left: auto;

}

// ==================================
.upload_img1 {
    position: relative;

    img {
        width: 100%;
        height: 100%;
        max-height: 300px;
        object-fit: revert;
    }

    .fa-solid {
        position: absolute;
        top: 10px;
        right: 10px;
        color: red;
        font-size: 20px;
        cursor: pointer;
    }
}

.upload_img2 {
    position: relative;

    img {
        width: 100%;
        height: 100%;
        max-height: 500px;
        object-fit: revert;
    }

    .fa-solid {
        position: absolute;
        top: 10px;
        right: 10px;
        color: red;
        font-size: 20px;
        cursor: pointer;
    }

}

.upload-box {
    width: 100%;
    height: 250px;
    max-height: 100%;

    input[type="file"] {
        visibility: hidden;
    }

    .uploadIcon {
        display: block;

        img {
            width: 100px;
        }
    }
}

.password-eye img {
    width: 100%;
    max-width: 30px;
}

.needs-validation .form-control {
    background-image: none !important;
}

.form-control:disabled {
    background-color: #f2f2f2!important;
}

.react-select {
    border-radius: 0 !important;
}

.was-validated .react-select.is-invalid div {
    border-color: #dc3545 !important;
    border-radius: 5px !important;
}

.was-validated .react-select.is-invalid div:hover {
    border-color: #dc3545 !important;
}

.was-validated .react-select.is-valid div {
    border-color: #198754 !important;
    border-radius: 5px !important;
}

.was-validated .react-select.is-valid div:hover,
.was-validated .react-select.is-valid div:active {
    border-color: #198754 !important;
}

.was-validated .react-select div:hover {
    border-color: rgba(var(--dark-blue-rgb), 0.5) !important;
}

.modal-dialog-scrollable {
    height: auto !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}
.custom-button {
    margin-left: 30px;
    width: 10%;
}

.main-pdf-box1 .pdf-box:hover {
    background: #0000001f !important;
    // background-image: url("../../../public/images/test1.avif") !important;
    padding: 25px;
    border-radius: 10px !important;
}

.showBtnNew {
    opacity: 0;
    position: absolute;
    top: 45%;
    color: white !important;
    left: 26%;
    background: #001935;
    border-radius: 10px;
    padding: 4px 20px;
    cursor: pointer;
}

.ar-word-break {
    word-break: break-all !important;
}

.main-pdf-box1 .pdf-box:hover .showBtnNew {
    opacity: 1 !important;
}

.main-pdf-box1 .pdf-box {
    padding: 25px;
}

.main-pdf-box1 .pdf-box:hover .pdf-icon img {
    filter: blur(3px) !important;
}

.social_media_type {
width: 20%;
   min-width: 124px;
   height: 40px !important;
}

.social_media_link {
   width: 80%;
   max-width: calc(100% - 124px);
}

.cardNew {
    margin-bottom: 0px !important;
}

.view_candidate_modal section.job-details.ptb-100.job-section.job-details-section {
    padding-top: 9px !important;
}

small.text-right.badge.bg-secondary.notification-badge {
    padding: 2px;
    background-color: #435362 !important;
    margin-left: 2px !important;
    margin-right: 2px !important;
}

.email_img img {
    max-width: 200px;
}
  
.email_img {
    display: flex;
    gap: 30px;
}

@media screen and (max-width:992px) and (min-width:767px) {
    .manage_box {
        .text-muted {
            margin-top: 32px !important;
            margin-bottom: 20px !important;
        }
    }
}
@media screen and (max-width:1200px) {
.manage_candiadte_favourite{
    justify-content: start !important;
}
.pb_remove{
    padding-bottom: 0px !important;
}
}
@media screen and (max-width:1090px) {
.job-card-two .manage_job_card{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
   
}
}

@media screen and (max-width:1049px) {
.bs-tooltip-top {
    opacity:0 !important;
}
}
@media screen and (min-width:992px) and (max-width:1400px) {
    .navbar-nav {
        .nav-item{
            a{
                font-size: 15.5px;
                white-space: nowrap;
            }
        }
    }  
}
@media screen and (max-width:992px) {
    .banner-content h1{
         font-size: 45px !important;
    }
    .info_margin {
        margin: 30px 0px !important;
    }

    .contact_padding {
        padding: 0 !important;
    }

    .section_padding {
        padding: 0px 0px 30px 0px !important;
    }
}

@media screen and (max-width:768px) {
    // .social_media_link {
    //     max-width: 80%;
    // }

    .dBlock {
        display: block;
    }

    .dNone {
        display: none;
    }

    .cursorPointer {
        cursor: pointer !important;
    }
}

.customSelectD {
    z-index: 9999 !important;
}

.p0 {
    padding: 0 !important;
}

.p1 {
    padding: 8px !important;
}

.ps12 {
    padding-left: 12px !important;
}

.ar-company-detail-componet{
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
}
@media screen and (max-width:767px) {
    .mail_compose .block-header {
        margin-bottom: 0 !important;
    }
    .chat {
        border: none !important;
    }
    .candiadate_serch_header{
        .search-menu {
            height: auto !important;
            white-space: nowrap;
            display: flex;
            flex-shrink: 0;
            align-items: center;
            background-color: var(--header-bg-color);
            border-radius: 8px;
            width: 100%;
            padding-left: 20px;
            column-gap: 15px;
            flex-wrap: wrap;
            .search-bar,.search-location,.search-salary,.search-button{
                width: 100% !important;
                max-width: 100% !important;
               
            }
            .search-bar{
                margin-top: 20px;
            }
            .search-location,.search-salary{
                padding: 12px 6px !important;
                margin-top: 10px;
            } 
            .search-button{
                margin: 20px 0px 30px 0px  !important;
                border-radius: 8px !important;
            }
        }
    }
    .home-section{
        .more_data {
            a {
                background: #2443ac;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 600;
                width: 100%;
                max-width: 107px !important;
                color: #ffffff;
                border-radius: 5px !important;
                top: 25px;
                position: relative;
                padding: 9px 10px !important;
                transition: 0.5s;
                display: block;
                text-align: center;
            }
        }
    }
}

@media screen and (max-width:575px) {
    .account_seeting_toggle {
        margin-left: 12px;
    }
}
@media screen and (max-width:991.98px) {
    .manage_header_btn .signin-btn,.manage_header_btn .signup-btn{
        margin-top: 15px;
    } 
    .btn_below_mobile{
        display: flex !important;
        width: 100% !important;
    }
    .btn_above_mobile{
    display: none !important;
    }
    }
    @media screen and (max-width:615px) {
    .candiate_recruitment_btn,.candidate_recruitment_portal_btn{
        display: none;
    }
    }
@media screen and (max-width:540px) {
    .recruitment-logo {
        display: none !important;
    }
}

@media screen and (max-width:520px) {

    .props_btn {
        text-align: left !important;
    }

    .recruitment-logo {
        display: none !important;
    }

    .fontSize_Manage {
        font-size: 15px !important;
    }

    // .social_media_type {
    //     width: 100%;
    //     max-width: 100% !important;
    // }

    // .social_media_link {
    //     width: 100%;
    //     max-width: 100% !important;
    //     margin-top: 20px;
    // }

    .new_social_btn {
        text-align: left !important;
        padding: 0 !important;
    }

    .mobile_card {
        padding: 0 !important;

        .mobile_card_padding {
            padding: 0 !important;

            .custom_card {
                -bs-card-spacer-y: 0 !important;
                --bs-card-spacer-x: 0 !important;
                --bs-card-title-spacer-y: 0 !important;
                --bs-card-title-color: ;
                --bs-card-subtitle-color: ;
                --bs-card-border-width: 0 !important;
                --bs-card-border-color: 0 !important;
                --bs-card-border-radius: 0 !important;
                --bs-card-box-shadow: ;
                --bs-card-inner-border-radius: 0 !important;
                --bs-card-cap-padding-y: 0 !important;
                --bs-card-cap-padding-x: 0 !important;
                --bs-card-cap-bg: 0 !important;
                --bs-card-cap-color: ;
                --bs-card-height: ;
                --bs-card-color: ;
                --bs-card-bg: 0 !important;
                --bs-card-img-overlay-padding: 0 !important;
                --bs-card-group-margin: 0 !important;.job-card-two .manage_job_card{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 40px;
                }
                position: relative;
                display: flex;
                flex-direction: column;
                min-width: 0;
                height: auto !important;
                color: var(--bs-body-color);
                word-wrap: break-word;
                background-color: var(--bs-card-bg);
                background-clip: 0 !important;
                border: none !important;
                border-radius: 0 !important;
            }

            .box-shadow {
                box-shadow: none !important;

                .body {
                    padding: 0 !important;

                    .content {
                        padding: 20px !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:450px) {
    .manage_nav_flex {
        display: flex;
        flex-direction: column !important;
    }

    // .card {
    //     .top_widget {
    //         .text {
    //             padding-right: 60px;
    //         }
    //     }
    // }
}


.languageSelect {
    // on hover of the dropdown show hand cursor
    &:hover {
        cursor: pointer;
    }

}

@media screen and (max-width: 397.8px){
    .company_send_detail_chat{
       
        margin-left: 1px !important;
    }
}
@media screen and (max-width: 388.8px){
    .company_send_detail_chat{
     margin-top: 10px !important;
    }
}
@media screen and (max-width: 369.8px){
.company_recive_detail_chat {
    margin-top: 10px !important;
    margin-right: 8px !important;
}

}

.ar-consult-filter{
    width: 150px !important;

  }

.react-datepicker__close-icon::after{
    background-color: #010c29;
}